import request from '@/utils/request'

/**
 * 角色管理
 */
//角色列表
export const employeerolelist = (data) => request('/admin/role/roleList', data);
//门店标签
export const shoptaglist = (data) => request('/pc/shop/shoptaglist', data);
//门店列表
export const employeeshoplist = (data) => request('/pc/employee/employeeshoplist', data);
//保存
export const addRole = (data) => request('/admin/role/addRole', data);
//编辑
export const editRole = (data) => request('/admin/role/editRole', data);
//获取角色信息
export const employeeroleinfo = (data) => request('/pc/employee/employeeroleinfo', data);
//删除角色
export const employeeroledelete = (data) => request('/admin/role/deleteRole', data);


/**
 * 员工管理
 */
//员工列表
export const employeelist = (data) => request('/admin/user/userList', data);
//添加员工
export const addUser = (data) => request('/admin/user/addUser', data);
//编辑员工
export const editUser = (data) => request('/admin/user/editUser', data);
//获取角色列表
export const employeeroledroplist = (data) => request('/admin/role/roleList', data);
//获取门店列表
export const shopdroplist = (data) => request('/pc/shop/shopdroplist', data); 
//获取员工详细信息 
export const employeeinfo = (data) => request('/pc/employee/employeeinfo', data);
//删除员工
export const employeedelete = (data) => request('/admin/user/deleteUser', data);
//离职员工
export const employeedeparture = (data) => request('/pc/employee/employeedeparture', data);
//获取员工客户列表
export const lockfansmemberlist = (data) => request('/pc/employee/lockfansmemberlist', data);
//修改锁粉店员
export const editlockmallemployeeid = (data) => request('/pc/employee/editlockmallemployeeid', data);
//修改用户状态
export const updateUserStatus = (data) => request('/admin/user/updateUserStatus', data);


// 获取员工匿名客户
export const anonymousFansList = (data) => request('/pc/employee/anonymousFansList', data);

//获取oss签名
export const getOssSignature = (data) => request('/api/oss/getOssSignature', data);

/**
 * 下载中心*/
//获取下载列表分页数据
export const fileDownloadList = (data) => request('/admin/fileDownload/list', data);