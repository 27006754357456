<template>
    <div style=" margin-top: 15px; background: #fff; padding: 15px 10px;">
        <el-form inline size="small">
            <el-form-item>
                <el-button icon="el-icon-refresh" type="success"  @click="search">刷新</el-button>
            </el-form-item>
        </el-form>

        <el-table :data="filter.list" v-loading="tableLoading" border size="mini">
            <el-table-column prop="contentDesc" label="文件名称"></el-table-column>
            <el-table-column prop="sourceFrom" label="文件来源">
                <template slot-scope="scope">
                    <span v-if="scope.row.sourceFrom === 'gsa'">GSA</span>
                    <span v-if="scope.row.sourceFrom === 'smcb'">in小鲜</span>
                    <span v-if="scope.row.sourceFrom === 'dms'">经销商管理系统</span>
                </template>
            </el-table-column>
            <el-table-column prop="fileFormat" label="文件格式">
                <template slot-scope="scope">
                    <span v-if="scope.row.fileFormat === 1">excel</span>
                    <span v-if="scope.row.fileFormat === 2">zip</span>
                </template>
            </el-table-column>
            <el-table-column prop="fileStatus" label="文件状态">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.fileStatus === 0" type="warning" size="mini">生成中</el-tag>
                    <el-tag v-if="scope.row.fileStatus === 1" type="success" size="mini">正常</el-tag>
                    <el-tag v-if="scope.row.fileStatus === 2" type="danger" size="mini">生成失败</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="backUserName" label="创建人"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.fileStatus === 1" type="text"  @click="downloadFunc(scope)">下载</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                       :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
                       @current-change="currentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>

    </div>
</template>

<script>
    import {fileDownloadList} from '@/api/api';
    import buttonPermissions from '@/components/buttonPermissions';

    export default {
        name: 'download',
        components: {
            buttonPermissions,
        },
        data() {
            return {
                tableLoading: false,
                filter: {
                    keywords: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
                exportLoading: false,
                createTimes: null, //日期
            };
        },
        beforeMount() {
            this.search();
        },
        methods: {
            search() {
                this.filter.pageNo = 1;
                this.getList()
            },
            // 翻页
            currentChange(val) {
                this.filter.pageNo = val;
                this.getList();
            },
            // 切换显示条数
            pageSizeChange(val) {
                this.filter.pageSize = val;
                this.getList();
            },
            async getList() {
                let params = {
                    keywords: this.filter.keywords,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                };
                this.tableLoading = true;
                try {
                    let { data } = await fileDownloadList(params);
                    if(data) {
                        this.filter.list = data.records || [];
                        this.filter.total = data.total;
                    }
                } catch (e) {
                   console.log(e);
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
            async downloadFunc({row, $index}) {
                try {
                    this.exportLoading = true;
                    let a = document.createElement("a");
                    a.download = row.contentDesc + ".xls";
                    a.href = row.downloadUrl;
                    document.body.append(a); // 修复firefox中无法触发click
                    a.click();
                    a.remove();
                } catch (e) {
                    console.log(e);
                }
                this.exportLoading = false;
            },
        },
    }
</script>